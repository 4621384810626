import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack } from "@mui/material";
import React from "react";
import { EnterSiteButton, HeroBot, HeroMid, HeroTop, Logo } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">WOW69</VisuallyHidden>

      <Stack
        alignItems="center"
        justifyContent="center"
      >
        <img
          alt="logo"
          src={Logo}
          width={300}
          style={{ marginBottom: "-10px" }}
        />

        <Box sx={{ zIndex: 1000, display: "flex", alignItems: "center" }}>
          <img
            alt="enter site button"
            src={EnterSiteButton}
            width={280}
          />
        </Box>
      </Stack>

      <Box sx={styles.hero}>
        <img
          alt="hero bot"
          src={HeroBot}
          width={360}
          style={{ top: "0", left: "0", animation: "heroBot 1.2s ease-in infinite" }}
        />
        <img
          alt="hero mid"
          src={HeroMid}
          width={316}
          style={{ top: "125px", left: "18px", animation: "heroMid 1.2s ease-in infinite" }}
        />
        <img
          alt="hero top"
          src={HeroTop}
          width={200}
          style={{ top: "89px", left: "84px", animation: "heroTop 1.2s ease-in infinite" }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  hero: {
    marginTop: "-70px",
    position: "relative",
    width: "360px",
    height: "424px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;

import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://peneciesmantable.com/302225a3-cdf0-4e10-9c63-3bcee25b09ce";
export const REGISTER_URL = "https://peneciesmantable.com/5797b23e-1351-424d-b873-ae00f8e6ba9d";

export const DEFAULT_ENTER_SITE_URL = "https://a.ntrk2.com/c7a00fa0-51e0-47d6-bcb0-0362793a11f1";

export const ENTER_SITE_URL_MAP = {
  "www-wow69.co": "https://a.ntrk2.com/c7a00fa0-51e0-47d6-bcb0-0362793a11f1",
  "www-wow69play.com": "https://a.ntrk2.com/0d4857fa-cb98-4864-87a5-d4c143230be2",
};

export const FLOATING_BANNER_URL = "https://wow699.com/cs";

export * from "./codes";
export * from "./context";
